import React from 'react'

import IcClose from '@interco/icons/core/action-navigation/ic_close'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { orange } from 'src/styles/colors'
import { ImgWebp } from 'src/styles/imgWebp'
import { CloseButtonContainer, Container, PopupContent } from './_popupConsignadoStyles'

type PopupConsignadoProps = {
  setHidePopupSessionStorage: (state: boolean) => void;
  closeModal: () => void;
}

const PopupConsignado = ({
  setHidePopupSessionStorage,
  closeModal,
}: PopupConsignadoProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const handleClick = () => {
    closeModal()
    setHidePopupSessionStorage(true)
  }

  return (
    <Container className='container'>
      <CloseButtonContainer
        onClick={handleClick}
      >
        <IcClose
          width='30'
          height='30'
          color={orange.extra}
          className='close_button'
        />
      </CloseButtonContainer>
      <PopupContent className='row popup_content'>
        <div className='popup_content__image col-12 col-lg-6 d-flex justify-content-md-center justify-content-lg-start'>
          <ImgWebp
            src='https://central-imagens.bancointer.com.br/images-without-small-versions/popup-image-consignado-publico-inter/image.webp'
            alt='Consignado Inter, Sempre aqui por você'
            breakPointsSize={{ sm: '264px', md: '334px', lg: '334px', xl: '334px' }}
          />
        </div>
        <div className='popup_content__texts col-12 col-lg-6'>
          <h2 className='popup_content__texts__title'>
            Seu salário aumenta, sua margem também!
          </h2>
          <p className='popup_content__texts__description'>
            Empréstimo com parcela mínima de R$22/mês e taxas reduzidas.
          </p>
        </div>

        <div className='popup_content__contract_button col-lg-12'>
          <a
            className='btn btn--lg bg-orange--extra text-none text-white rounded-2 fs-14 lh-16 fw-700 mw-100'
            href='https://simulador-consignado.inter.co/simulador/dados-basicos?utm_source=popup&utm_medium=site&utm_campaign=sempreaquiporvc'
            target='_blank'
            rel='noopener noreferrer'
            onClick={() => {
              setHidePopupSessionStorage(false)
              sendDatalayerEvent({
                section: 'dobra_01',
                element_action: 'click button',
                section_name: 'Seu negócio no topo Soluções Inter Empresas pensadas para alavancar o seu negócio.',
                element_name: 'Quero contratar',
                redirect_url: 'https://simulador-consignado.inter.co/simulador/dados-basicos?utm_source=popup&utm_medium=site&utm_campaign=sempreaquiporvc',
              })
            }}
          >
            Quero contratar
          </a>
        </div>
      </PopupContent>
    </Container>
  )
}

export default PopupConsignado
