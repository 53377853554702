import { breakpoints, device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.section`
  display: flex;
  align-items: center;

  @media (min-width: ${breakpoints.lg}) {
    min-height: 599px;
  }

  @media (min-width: ${breakpoints.xl}) {
    min-height: 663px;
  }

  .card-icon {
    @media (min-width: ${breakpoints.md}) {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        margin-bottom: 69px;
      }
    }
  }
`

export const ListWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 32px;

  @media (min-width: ${breakpoints.md}) {
    flex-direction: column;
    text-align: center;
    margin-top: 50px;
  }
`

export const Text = styled.span`
  display: block;
  font-size: 16px;
  line-height: 19px;
  color: ${grayscale[500]};
  margin-left: 15px;

  @media (min-width: ${breakpoints.md}) {
    font-size: 14px;
    line-height: 17px;
    margin-left: 0;
    margin-top: 22px;
  }

  @media (min-width: ${breakpoints.lg}) {
    font-size: 18px;
    line-height: 22px;
  }

  @media (min-width: ${breakpoints.xl}) {
    padding: 0 50px;
  }
`

export const Title = styled.h3`
  display: block;
  font-family: "Sora", Helvetica, sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: ${grayscale[500]};
  margin-bottom: 16px;

  @media ${device.tablet} {
    font-size: 40px;
    line-height: 50px;
  }
`

export const Description = styled.span`
  display: block;
  font-size: 16px;
  line-height: 19px;
  color: ${grayscale[500]};

  @media ${device.tablet} {
    font-size: 18px;
    line-height: 22px;
    padding: 0 8px;
  }

  @media ${device.desktopXL} {
    padding: 0;
    margin-bottom: 16px;
  }
`
