import { breakpoints } from 'src/styles/breakpoints'
import { grayscale, white } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.section`
  display: flex;
  align-items: center;
  background: ${grayscale[100]};

  @media (min-width: ${breakpoints.md}) {
    min-height: 553px;
  }

  @media (min-width: ${breakpoints.lg}) {
    min-height: 587px;
  }

  @media (min-width: ${breakpoints.xl}) {
    min-height: 651px;
  }

  .gatsby-image-wrapper {
    @media (min-width: ${breakpoints.xl}) {
      width: 456px;
    }
  }

  .card-list {
    margin-top: 16px;

    @media (min-width: ${breakpoints.md}) {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        margin-top: 0;
      }
    }
    @media (min-width: ${breakpoints.md}) {
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(7) {
        margin-top: 22px;
      }
    }
    @media (min-width: ${breakpoints.lg}) {
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(7) {
        margin-top: 32px;
      }
    }
  }

  label {
    margin-bottom: 0;
  }
`

export const ColapseList = styled.div`
  width: 100%;
  position: relative;
`
export const ColapseListButton = styled.label`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 28px 25px;
  border-radius: 8px;
  background: ${white};
  border: 1px solid ${white};
  box-sizing: border-box;
  transition: background 0.5s ease-in-out;

  @media (min-width: ${breakpoints.md}) {
    height: 82px;
    padding: 0 25px;
    font-size: 14px;
    line-height: 17px;

    span {
      padding-right: 30px;
    }
  }

  @media (min-width: ${breakpoints.lg}) {
    height: 72px;
  }

  &:focus {
    outline: none;
  }

  .icon {
    transition: transform 0.5s ease-in-out;
  }
`

export const List = styled.ul`
  width: 100%;
  list-style: none;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 0;
  margin: 4px 0 0 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
  max-height: 0px;
  background:${white};
  z-index: 1;
  position: absolute;
  top: ColapseListButton;

  li {
    padding: 28px 25px;
    box-sizing: border-box;

    & + li {
      border: 1px solid ${grayscale[100]};
    }
  }
`
export const Checkbox = styled.input`
  display: none;

  &:checked + div label {
    background: ${white};
    border: 1px solid #DEDFE4;
  }

  &:checked + div label .icon {
    transform: rotate(180deg);
  }

  &:checked + div ul {
    max-height: 870px;
  }
`

export const Title = styled.span`
  display: block;
  font-family: "Sora", Helvetica, sans-serif;
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  color: ${grayscale[500]};
  margin-bottom: 16px;

  @media (min-width: ${breakpoints.md}) {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 21px;
  }

  @media (min-width: ${breakpoints.lg}) {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 16px;
  }
`

export const Description = styled.span`
  display: block;
  font-size: 16px;
  line-height: 19px;
  color: ${grayscale[500]};
  margin-bottom: 24px;
  padding-right: 5px;

  @media (min-width: ${breakpoints.md}) {
    font-size: 15px;
    margin-bottom: 47px;
    padding-right: 0;
  }

  @media (min-width: ${breakpoints.lg}) {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 69px;
    padding: 0 8px;
  }
`
