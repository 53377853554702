import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.section`
  a, button {
    max-width: none;
  }

  @media ${device.tablet} {
    min-height: 590px;
  }

  @media ${device.desktopLG} {
    min-height: 616px;
  }

  @media ${device.desktopXL} {
    min-height: 680px;
  }
`

export const Indicate = styled.div`
 .padding-left {
   padding-left: 9px;
 }
`

export const Title = styled.span`
  display: block;
  font-family: "Sora", Helvetica, sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: ${grayscale[500]};
  margin-bottom: 16px;

  @media ${device.tablet} {
    font-size: 32px;
    line-height: 40px;
  }

  @media ${device.desktopLG} {
    font-size: 40px;
    line-height: 50px;
  }
`
