import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

export const Container = styled.div`
  background-color: #FDF8EF;
  padding: 24px;

  box-shadow: 0px 24px 64px -8px #16161629;
  border-radius: 16px;

  position: relative;
  width: 312px;

  @media ${device.tablet} {
  padding: 40px;
  width: 544px;
  }

  @media ${device.desktopLG} {
    width: 768px;
  }
`

export const CloseButtonContainer = styled.div`
  position: absolute;
  z-index: 12;

  right: 24px;
  top: 24px;

  @media ${device.tablet} {
    right: 40px;
    top: 40px;
  }

  .close_button {
    height: 32px;
    width: 32px;
  }
`

export const PopupContent = styled.article`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background-color: #FDF8EF;

    @media ${device.desktopLG} {
      justify-content: space-between;
    }

  .popup_content{
    &__texts{
      &__title{
        font-family: "Inter", Helvetica, sans-serif;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: ${grayscale[500]};

        margin-bottom: 8px;

        @media ${device.tablet} {
          margin-bottom: 16px;
        }

        @media ${device.desktopLG} {
          font-size: 24px;
          line-height: 29px;

          margin-bottom: 16px;
        }
      }

      &__description {
        font-family: "Inter", Helvetica, sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: ${grayscale[500]};
        margin-bottom: 0;

        @media ${device.desktopLG} {
          font-size: 16px;
          line-height: 19px;
        }
      }
    }

    &__contract_button {
      margin-top: 24px;
    }
  }
`
